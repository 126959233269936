import { ACCOUNTANT_ROLE, ADMIN_ROLE, RENTER_ROLE } from '../../store/User';
import {
  LuBuilding2,
  LuCable,
  LuCoins,
  LuCrown,
  LuUser,
  LuUsers
} from 'react-icons/lu';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from '../ui/sheet';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '../ui/button';
import config from '../../config';
import { RiContractLine } from 'react-icons/ri';
import { Separator } from '../ui/separator';
import SideMenuButton from '../SideMenuButton';
import { StoreContext } from '../../store';
import UserAvatar from '../UserAvatar';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const menuItems = [
  [
    {
      key: 'account',
      Icon: LuUser,
      labelId: 'Your account',
      pathname: '/settings/account',
      roles: [ADMIN_ROLE, RENTER_ROLE, ACCOUNTANT_ROLE],
      dataCy: 'accountNav'
    },
    {
      key: 'organizations',
      Icon: LuBuilding2,
      labelId: 'Your organizations',
      pathname: '/settings/organizations',
      roles: [ADMIN_ROLE, RENTER_ROLE, ACCOUNTANT_ROLE],
      dataCy: 'organizationsNav'
    }
  ],
  [
    {
      key: 'landlord',
      Icon: LuCrown,
      labelId: 'Landlord',
      pathname: '/settings/landlord',
      roles: [ADMIN_ROLE],
      dataCy: 'landlordNav'
    },
    {
      key: 'billing',
      Icon: LuCoins,
      labelId: 'Billing',
      pathname: '/settings/billing',
      roles: [ADMIN_ROLE],
      dataCy: 'billingNav'
    },
    {
      key: 'contracts',
      Icon: RiContractLine,
      labelId: 'Contracts',
      pathname: '/settings/contracts',
      roles: [ADMIN_ROLE],
      dataCy: 'contractsNav'
    },
    {
      key: 'access',
      Icon: LuUsers,
      labelId: 'Access',
      pathname: '/settings/access',
      roles: [ADMIN_ROLE],
      dataCy: 'accessNav'
    },
    {
      key: 'thirdparties',
      Icon: LuCable,
      labelId: 'Third-parties',
      pathname: '/settings/thirdparties',
      roles: [ADMIN_ROLE],
      dataCy: 'thirdpartiesNav'
    }
  ]
];

export default function OrganizationMenu({ className }) {
  const { t } = useTranslation('common');
  const store = useContext(StoreContext);
  const router = useRouter();
  const [selectedMenu, setSelectedMenu] = useState();

  const userMenuItems = menuItems.map((menuGroup) =>
    menuGroup.filter((menuItem) => menuItem.roles.includes(store.user.role))
  );

  useEffect(() => {
    const selectedMenuItems = userMenuItems
      .flat()
      .filter((menuItem) => router.pathname.endsWith(menuItem.pathname));
    let selectedMenuItem;
    if (selectedMenuItems.length > 0) {
      selectedMenuItem = selectedMenuItems[0];
    }
    setSelectedMenu(selectedMenuItem);
  }, [router.pathname, userMenuItems]);

  const handleSignOut = useCallback(
    async (event) => {
      event.preventDefault();
      await store.user.signOut();
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.location.assign(config.BASE_PATH); // will be redirected to /signin
    },
    [store.user]
  );

  const handleMenuClick = useCallback(
    (menuItem) => {
      setSelectedMenu(menuItem);
      router.push(
        `/${store.organization.selected.name}${menuItem.pathname}`,
        undefined,
        {
          locale: store.organization.selected.locale
        }
      );
    },
    [
      router,
      store.organization.selected?.locale,
      store.organization.selected?.name
    ]
  );

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className={className}>
          <Button variant="icon" data-cy="orgMenu">
            <UserAvatar className="cursor-pointer" />
          </Button>
        </div>
      </SheetTrigger>
      <SheetContent side="right" className="flex flex-col px-0">
        <SheetHeader className="flex flex-row items-center px-4">
          <SheetTitle>{`${store.user.firstName} ${store.user.lastName}`}</SheetTitle>
        </SheetHeader>
        <Separator className="bg-secondary-foreground/25" />
        {userMenuItems[0].map((item) => (
          <div key={item.key}>
            <SheetClose asChild>
              <SideMenuButton
                item={item}
                selected={item === selectedMenu}
                onClick={() => handleMenuClick(item)}
              />
            </SheetClose>
          </div>
        ))}

        {userMenuItems[1].length > 0 ? (
          <>
            <Separator className="bg-secondary-foreground/25" />
            <SheetDescription className="px-4">
              {t('Organization information', {
                organization: store.organization.selected.name
              })}
            </SheetDescription>

            {userMenuItems[1].map((item) => (
              <div key={item.key}>
                <SheetClose asChild>
                  <SideMenuButton
                    item={item}
                    selected={item === selectedMenu}
                    onClick={() => handleMenuClick(item)}
                  />
                </SheetClose>
              </div>
            ))}
            <Separator className="mt-4 mb-0 bg-secondary-foreground/25" />
          </>
        ) : null}

        <div>
          <SheetClose asChild>
            <SideMenuButton
              item={{
                key: 'signout',
                labelId: 'Sign out',
                dataCy: 'signoutNav'
              }}
              onClick={handleSignOut}
            />
          </SheetClose>
        </div>
      </SheetContent>
    </Sheet>
  );
}
